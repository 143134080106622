import * as actionTypes from '../actions/ActionTypes';
import { DispatchedAppSettingAction } from '../actions/AppSettingAction';

export interface AppReducerStore {
  appStartCount: number;
  isAllEmployeesLoaded: boolean;
}

export const appSettingsReducerInitialState: AppReducerStore = {
  appStartCount: 0,
  isAllEmployeesLoaded: false,
};

function AppReducer(state = appSettingsReducerInitialState, action: DispatchedAppSettingAction): AppReducerStore {
  switch (action.type) {
    case actionTypes.APP_STARTED: {
      return {
        ...state,
        appStartCount: state.appStartCount + 1,
        isAllEmployeesLoaded: true,
      };
    }
    default:
      return {
        ...state,
      };
  }
}
export default AppReducer;
