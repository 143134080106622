import { AxiosError, AxiosResponse } from 'axios';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { WorkloadStatusFilterValues } from '../../components/Filter/WorkloadStatusFilter';
import * as appServicePostOrPut from '../../services/appServices/appRefreshService';
import {
  createEmailFromUniqueEmployeeName,
  createUniqueEmployeeNameFromUrl,
  getEmployeeFromEmail,
} from '../../utils/employeeUtils';
import { createTeamData, sortTeamDataManually } from '../../utils/teamNameUtil';
import { ApplicationStore } from '../reducers';
import { Employee, Status, TeamData } from '../reducers/employee/employee.type';
import { WeeklyStandupFilterValues } from './../../components/Filter/WeeklyStandupFilter';
import * as actionTypes from './ActionTypes';
import { projectDataAvailable } from './ProjectActions';
import { addToast } from './ToastActions';

export const teamDataAvailable = (allPrimaryTeams: string[], allSubTeams: string[]) => {
  return (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    const teamData: TeamData[] = [];

    allPrimaryTeams.forEach((primaryTeam) => {
      const subTeams = allSubTeams.filter((subTeam) => subTeam.startsWith(primaryTeam));
      teamData.push(createTeamData(primaryTeam, subTeams));
    });

    dispatch({
      payload: sortTeamDataManually(teamData),
      type: actionTypes.TEAM_DATA_AVAILABLE,
    });
  };
};

export const employeeDataAvailable = (employeeData: Employee[]) => {
  return {
    payload: employeeData,
    type: actionTypes.EMPLOYEES_DATA_AVAILABLE,
  };
};

interface FilterEmployeeValues {
  teamFilter?: string;
  weeklyStandupFilter?: WeeklyStandupFilterValues;
  workloadStatusFilter?: WorkloadStatusFilterValues;
}

export const setEmployeeFilters = (
  filterEmployeeValues: FilterEmployeeValues,
): { payload: FilterEmployeeValues; type: string } => ({
  payload: filterEmployeeValues,
  type: actionTypes.SET_EMPLOYEE_FILTERS,
});

export const saveDisplayedEmployees = (displayedEmployees: Employee[]) => ({
  payload: displayedEmployees,
  type: actionTypes.DISPLAYED_EMPLOYEES,
});

export const teamFilterAvailable = (shownSpecificTeam: string) => {
  return (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>) => {
    dispatch({
      payload: shownSpecificTeam,
      type: actionTypes.TEAM_FILTER_DATA_AVAILABLE,
    });
  };
};

export const editEmployee = (changesStatus: Status, emailFromEmployee: string, absenceChanged: boolean) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>, getState: () => ApplicationStore) => {
    // Send Overlay Changes to database
    await appServicePostOrPut
      .putEditedEmployeeStatus(changesStatus, emailFromEmployee)
      .then((res: AxiosResponse<string>) => {
        if (res.status !== 200) {
          absenceChanged && dispatch(addToast('Es gab Probleme beim aktualisieren der Abwesenheit.', 'error'));
        } else {
          absenceChanged && dispatch(addToast('Die Abwesenheit wurde erfolgreich aktualisiert', 'success'));
        }
        // Update project and employee data in frontend
        const state = getState();
        const employees = state.employee.employeeData;
        const newEmployeeList = employees.map((employee) =>
          employee.email === emailFromEmployee ? { ...employee, status: changesStatus } : employee,
        );
        dispatch(employeeDataAvailable(newEmployeeList));
        return res.data;
      })
      .catch((err: AxiosError) => {
        absenceChanged && dispatch(addToast('Es gab Probleme beim hinzufügen der Abwesenheit.', 'error'));
        console.log('Problem in EmployeeActions.js, check error: ' + JSON.stringify(err));
        return err;
      });
  };
};

export const fetchEmployee = (employeeEmail: string) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>, getState: () => ApplicationStore) => {
    const employees = getState().employee.employeeData;
    const employee = getEmployeeFromEmail(employeeEmail, employees);
    if (employee) {
      dispatch({ type: actionTypes.SET_EMPLOYEE, payload: employee });
    }
    try {
      const employeeFromFetch = await appServicePostOrPut.getEmployee(employeeEmail);
      if (createEmailFromUniqueEmployeeName(createUniqueEmployeeNameFromUrl(window.location.href)) !== employeeEmail) {
        return;
      }
      dispatch({ type: actionTypes.SET_EMPLOYEE, payload: employeeFromFetch });
      const projectNamesToUpdate = employeeFromFetch.projects;
      const updatedProjects = await appServicePostOrPut.getProjects(projectNamesToUpdate);
      const projectData = getState().project.projectData;

      const newProjectList = [
        ...projectData.filter((project) => !projectNamesToUpdate.includes(project.name)),
        ...updatedProjects,
      ];

      dispatch(projectDataAvailable(newProjectList));
    } catch (error) {
      console.log('Problem beim Zugriff auf Employee: ' + JSON.stringify(error));
    }
  };
};
