import { AxiosResponse } from 'axios';

import { Project } from '../../redux/actions/ProjectActions';
import { Employee, Status } from '../../redux/reducers/employee/employee.type';
import { deleteApi, getData, postData, putData } from '../../utils/axios-calls';

const handleResponse = (res: AxiosResponse): number => {
  return res.status;
};

export const putEditedEmployeeStatus = (updatedStatus: Status, email: string): Promise<AxiosResponse<string>> => {
  const urlStatusData = '/status/' + email;
  const responseProjectData = putData<Status, string>(urlStatusData, updatedStatus);
  return responseProjectData.then((res: AxiosResponse<string>) => {
    return res;
  });
};

export const postNewProject = (changesNewProject: any, callback: any): Promise<unknown> => {
  const urlProjectData = '/project';
  const responseProjectData = postData(urlProjectData, changesNewProject);
  return responseProjectData
    .then((res: any) => {
      callback(handleResponse(res));
      return res;
    })
    .catch((err: any) => {
      callback(handleResponse(err));
      return err.data;
    });
};

export const putUpdatedProject = (updatedProject: any, callback: any): Promise<unknown> => {
  const urlProjectData = '/project/update';
  const responseProjectData = putData(urlProjectData, updatedProject);
  return responseProjectData
    .then((res: any) => {
      callback(handleResponse(res));
      return res;
    })
    .catch((err: any) => {
      callback(handleResponse(err));
      return err.data;
    });
};

export const deleteProject = (projectName: string): Promise<unknown> => {
  const urlProjectData = '/project/delete/';
  return deleteApi(urlProjectData + encodeURIComponent(projectName))
    .then((res: any) => {
      return res;
    })
    .catch((error: any) => {
      console.error('Error occurred while deleting project: [%s]', projectName);
      return error;
    });
};

export const archiveProject = (projectName: string, isArchived: boolean): Promise<unknown> => {
  const urlProjectData = '/project/archive/';
  const isArchivedRequestParam = '?flag=' + isArchived;
  return putData(urlProjectData + projectName + isArchivedRequestParam, '')
    .then((res: any) => {
      return res;
    })
    .catch((error: any) => {
      console.error('Error occurred while archiving or unarchiving project: [%s]', projectName);
      return error;
    });
};

export const getEmployee = (email: string): Promise<Employee> => {
  const urlGetEmployee = `/employee/byMail/${email}`;
  return getData(urlGetEmployee)
    .then((res: any) => {
      return res.data;
    })
    .catch((error: any) => {
      console.error('Error occurred while fetching employee: [%s]', email);
      return error;
    });
};

export const getProjects = async (projectNames: string[]): Promise<Project[]> => {
  const urlGetProjects = `/project/byNames?name=${projectNames
    .map((projectName) => encodeURIComponent(projectName))
    .join('&name=')}`;
  return getData(urlGetProjects)
    .then((res: any) => {
      return res.data;
    })
    .catch((error: any) => {
      console.error('Error occurred while fetching projects by names: [%s]', projectNames);
      return error;
    });
};
