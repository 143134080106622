import { useCallback, useEffect } from 'react';

export function useMountHook(callback: any, ref: any): void {
  //TODO: remove eslint disable line
  // eslint-disable-next-line
  callback = useCallback(callback, []);
  ref.current = callback;
  useEffect(() => {
    callback();
  }, [callback]);
}
