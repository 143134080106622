import { History } from 'history';

const EmptyHistory: History = {
  location: undefined,
  length: undefined,
  action: undefined,
  push: undefined,
  replace: undefined,
  go: undefined,
  goBack: undefined,
  goForward: undefined,
  block: undefined,
  listen: undefined,
  createHref: undefined,
};

export default EmptyHistory;
