import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';

import { configureMsal, loginRequest } from '../auth-config';

const appClientId = process.env.REACT_APP_CLIENT_ID;
const appTenantId = process.env.REACT_APP_CLIENT_TENANT_ID;
let msalInstance: PublicClientApplication | null = null;

async function getMsalInstance() {
  if (!msalInstance) {
    const msalConfig = configureMsal(appClientId, appTenantId);
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
  }
  return msalInstance;
}

async function getIdToken(): Promise<string> {
  const instance = await getMsalInstance();
  const accounts = instance.getAllAccounts();

  if (accounts.length === 0) {
    console.error('No account found. Please log in.');
    return null;
  }

  try {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });
    return response.idToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest);
    } else {
      console.error('Silent token acquisition failed', error);
    }
  }

  return null;
}

export { getIdToken };
