import { AxiosResponse } from 'axios';

import { Project } from '../../redux/actions/ProjectActions';
import { Employee } from '../../redux/reducers/employee/employee.type';
import { getBlobData, getData } from '../../utils/axios-calls';

const PATH_TEAM_DATA = '/team';
const PATH_TEAM_DATA_SUB_NAMES = PATH_TEAM_DATA + '/sub-names';
const PATH_TEAM_DATA_PRIMARY_NAMES = PATH_TEAM_DATA + '/primary-names';
const PATH_EMPLOYEE_DATA_FOLDER = '/employee';
const PATH_EMPLOYEE_DATA = PATH_EMPLOYEE_DATA_FOLDER + '/byTeam/';
const PATH_PROJECT_DATA = '/project';

const ERR_NO_DATA = (err: Error, path: string, data: Promise<AxiosResponse>): string =>
  `No data found at ${path} in ${data}. check error: ${err}`;

export const getPrimaryTeamData = (): Promise<string[]> => {
  const response = getData<string[]>(PATH_TEAM_DATA_PRIMARY_NAMES);
  return response
    .then((res) => res.data)
    .catch((err: Error) => {
      console.log(ERR_NO_DATA(err, PATH_TEAM_DATA, response));
      return undefined;
    });
};

export const getSubTeamData = (): Promise<string[]> => {
  const response = getData<string[]>(PATH_TEAM_DATA_SUB_NAMES);
  return response
    .then((res) => res.data)
    .catch((err: Error) => {
      console.log(ERR_NO_DATA(err, PATH_TEAM_DATA, response));
      return undefined;
    });
};

export const getImageData = (employeeId: string): Promise<Blob> => {
  const response = getBlobData(`employee/image/${employeeId}`);
  return response
    .then((res) => res)
    .catch((err: Error) => {
      return null;
    });
};

export const getEmployeeData = (teamName: string): Promise<Employee[]> => {
  const response = getData<string[]>(PATH_EMPLOYEE_DATA + teamName);
  return response
    .then((res) => res.data)
    .catch((err: Error) => {
      console.log(ERR_NO_DATA(err, teamName, response));
      return undefined;
    });
};

export const getProjectData = (): Promise<Project[]> => {
  const response = getData<string[]>(PATH_PROJECT_DATA);
  return response
    .then((res) => res.data)
    .catch((err: Error) => {
      console.log(ERR_NO_DATA(err, PATH_PROJECT_DATA, response));
      return undefined;
    });
};
