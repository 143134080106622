import { BillableFilterValues } from '../../components/Filter/BillableFilter';
import { EmployeeWorkloadFilterValues } from '../../components/Filter/EmployeeWorkloadFilter';
import { WorkloadProjectFilterValues } from '../../components/Filter/WorkloadProjectFilter';
import * as actionTypes from '../actions/ActionTypes';
import { DispatchedWorkloadAction } from '../actions/WorkloadActions';

export interface WorkLoadStore {
  redBoxShowed: boolean;
  billableFilter: BillableFilterValues;
  workloadLimit: EmployeeWorkloadFilterValues;
  workloadProjectType: WorkloadProjectFilterValues;
  employeeSearchValue: string;
}

export const workloadInitialData: WorkLoadStore = {
  redBoxShowed: false,
  billableFilter: BillableFilterValues.all,
  workloadLimit: EmployeeWorkloadFilterValues['all'],
  workloadProjectType: WorkloadProjectFilterValues.all,
  employeeSearchValue: '',
};

function WorkloadReducer(state = workloadInitialData, action: DispatchedWorkloadAction): WorkLoadStore {
  switch (action.type) {
    case actionTypes.DISPLAY_RED_BOXES: {
      return {
        ...state,
        redBoxShowed: action.payload,
      };
    }
    case actionTypes.SET_BILLABLE_FILTER_STATE: {
      return {
        ...state,
        billableFilter: action.payload,
      };
    }
    case actionTypes.SET_EMPLOYEE_WORKLOAD_LIMIT: {
      return {
        ...state,
        workloadLimit: action.payload,
      };
    }
    case actionTypes.SET_WORKLOAD_PROJECTS: {
      return {
        ...state,
        workloadProjectType: action.payload,
      };
    }
    case actionTypes.CHANGE_EMPLOYEE_SEARCH_VALUE: {
      return {
        ...state,
        employeeSearchValue: action.payload,
      };
    }
    default:
      return { ...state };
  }
}

export default WorkloadReducer;
