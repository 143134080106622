import { AccountInfo, AuthenticationResult, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { ThemeProvider } from '@material-ui/core';
import { History } from 'history';
import React, { useEffect } from 'react';
import { Switch, withRouter } from 'react-router-dom';

import App from './App';
import PrivateRoute from './components/PrivateRoute';
import EmptyHistory from './models/shared/EmptyHistory';
import exxetaBrandTheme from './themes/exxetaBrandTheme';

interface FrameProps {
  history?: History;
}

const Frame = (props: FrameProps): JSX.Element => {
  const [redirect, setRedirect] = React.useState('');

  const { instance } = useMsal();

  const { history } = props;

  useEffect(() => {
    instance.handleRedirectPromise().then((response: AuthenticationResult) => {
      if (response && response.account && history.location.pathname !== redirect) {
        // User is authenticated, you can proceed to  app
        setRedirect(history.location.pathname);
      }
    });
    // Check if the user is already signed in
    const account: AccountInfo = instance.getActiveAccount();
    if (account && history.location.pathname !== redirect) {
      // User is already signed in, you can proceed to  app
      setRedirect(history.location.pathname);
    } else {
      // If the user is not signed in, initiate the login process
      instance.initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ThemeProvider theme={exxetaBrandTheme}>
          <Switch>
            <PrivateRoute component={App} path="/" />
          </Switch>
        </ThemeProvider>
      </MsalAuthenticationTemplate>
    </>
  );
};

Frame.defaultProps = {
  history: EmptyHistory,
};

export default withRouter(Frame);
