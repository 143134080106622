import { Employee, WeeklyStandup } from '../../redux/reducers/employee/employee.type';

const EmptyEmployee: Employee = {
  email: 'vorname.nachname@exxeta.com',
  forename: 'Vorname',
  id: '',
  projects: [],
  surname: 'Nachname',
  teams: [],
  status: {
    workloadStatus: {
      iHaveTime: false,
      iNeedHelp: false,
    },
    weeklyStandupStatus: WeeklyStandup.I_AM_HERE,
    absenceStatus: [],
  },
  position: 'Position',
};

export default EmptyEmployee;
