import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { setEmployeeFilters } from '../../redux/actions/EmployeeActions';
import { ApplicationStore } from '../../redux/reducers';
import FilterBase, { FilterValue } from './FilterBase';

export enum WeeklyStandupFilterValues {
  'all',
  'present',
}

interface WeeklyStandupFilterProps {
  weeklyStandupFilter: WeeklyStandupFilterValues; // Der aktuelle Filterstatus (Redux)
  isAllEmployeesLoaded: boolean;
}

export const SEARCH_PARAM_WEEKLY_STANDUP_FILTER = 'anwesenheit';

const WeeklyStandupFilter = (props: WeeklyStandupFilterProps) => {
  const { weeklyStandupFilter, isAllEmployeesLoaded } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // Filterwerten für Menü
  const weeklyStandupFilterValuesItems: FilterValue[] = [
    { value: WeeklyStandupFilterValues.all, label: 'alle' },
    { value: WeeklyStandupFilterValues.present, label: 'ist diesmal dabei' },
  ];

  // URL Filter Variablen
  const location = useLocation();
  const history = useHistory();

  const changeWeeklyStandupURL = (weeklyStandup: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(SEARCH_PARAM_WEEKLY_STANDUP_FILTER, weeklyStandup.toString());

    if (weeklyStandup === 'alle') {
      searchParams.delete(SEARCH_PARAM_WEEKLY_STANDUP_FILTER);
    }

    const newSearchString = searchParams.toString();

    if (location.search !== newSearchString) {
      history.replace({ search: newSearchString });
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleWeeklyStandupFilterItemClicked = (filterValue: FilterValue): void => {
    dispatch(setEmployeeFilters({ weeklyStandupFilter: filterValue.value }));
    changeWeeklyStandupURL(filterValue.label.replaceAll(' ', '-'));
    handleMenuClose();
  };

  const handleWeeklyStandupFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(SEARCH_PARAM_WEEKLY_STANDUP_FILTER) && isAllEmployeesLoaded) {
      const weeklyStandupStatus = searchParams.get(SEARCH_PARAM_WEEKLY_STANDUP_FILTER);
      if (weeklyStandupStatus === 'ist-diesmal-dabei') {
        dispatch(setEmployeeFilters({ weeklyStandupFilter: WeeklyStandupFilterValues.present }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllEmployeesLoaded]);

  return (
    <FilterBase
      filterHeading={'Anwesenheit'}
      open={open}
      anchorEl={anchorEl}
      filterState={weeklyStandupFilterValuesItems.find((item) => item.value === weeklyStandupFilter)?.label || 'Alle'}
      filterValuesItems={weeklyStandupFilterValuesItems}
      handleMenuItemClick={handleWeeklyStandupFilterItemClicked}
      handleFilterClick={handleWeeklyStandupFilterClick}
      handleMenuClose={handleMenuClose}
    />
  );
};

// Redux-Map-Methoden
const mapStateToProps = (store: ApplicationStore) => ({
  employees: store.employee.employeeData, // Array von Mitarbeitern
  weeklyStandupFilter: store.employee.weeklyStandupFilter,
  isAllEmployeesLoaded: store.appConfig.isAllEmployeesLoaded,
});

export default connect(mapStateToProps)(WeeklyStandupFilter);
