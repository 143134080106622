import { useMsal } from '@azure/msal-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket } from '@fortawesome/sharp-light-svg-icons';
import { Divider, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ForwardRefExoticComponent, Ref, useEffect, useState } from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

//Font
import '../../Fonts.css';
import { BandeinsStrangeBold, senRegular } from '../../fonts/fonts';
//store
import colors from '../../utils/colors';
import { DialogMode } from '../Dialogs/DialogNewProject/DialogNewProject';
import ProjectSearchEditField from './ProjectSearchEditField';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    display: 'flex',
    position: 'sticky',
    top: '0',
    padding: '0px !important',
    height: '0 auto',
  },

  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  secondaryNavigation: {
    position: 'relative',
    padding: '0px',
    display: 'flex',
    flex: '1 1 0%',
    '& a': {
      width: 'inherit',
      margin: theme.spacing(3),
    },
  },

  mainNavigation: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '52px',
    width: '456px',
    borderRadius: '24px',
    background: colors.white,
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.16)',
    padding: '8px',
    margin: '16px 10px',
    gap: '40px',
  },

  avatarPic: {
    clipPath: 'inset(0% 12.5% 25% 12.5%)',
    height: '32px',
    width: '32px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  toolbarRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '1 1 0%',
  },

  logOut: {
    marginRight: theme.spacing(3),
  },

  logOutButton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
    '&:hover': {
      backgroundColor: colors.grey100,
    },
  },

  logoutButtonIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.grey800,
  },

  firstIcon: {
    visibility: 'hidden',
  },
  title: {
    fontSize: 22,
  },
  badgeText: {
    color: colors.white,
    fontSize: '0.8rem',
  },
  menuLabel: {
    padding: '6px 16px',
    gap: '8px',
    backgroundColor: 'transparent',
    colors: colors.mainColor,
    display: 'flex',
    alignItems: 'flex-start',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: senRegular,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '24px',
    borderRadius: '24px',
    transition: 'color 0.15s ease-out',
    zIndex: 1,
  },

  logo: {
    colors: colors.mainColor,
    backgroundColor: 'transparent',
    display: 'inline-flex',
    lineHeight: '36px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontFamily: BandeinsStrangeBold,
    fontSize: '24px',
  },

  cursorFollower: {
    position: 'absolute',
    height: '36px',
    borderRadius: '24px',
    backgroundColor: 'black',
    transition: 'left 0.15s ease-out, width 0.15s ease-in-out',
    transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
    pointerEvents: 'none',
  },

  listItem: {
    color: 'black',
    padding: 0,
  },
}));

interface AdapterLinkProps {
  children?: React.ReactNode;
  to: string;
}

const AdapterLink: ForwardRefExoticComponent<any> = React.forwardRef(
  (props: AdapterLinkProps, ref: Ref<HTMLAnchorElement>): JSX.Element => <NavLink innerRef={ref} {...props} />,
);
AdapterLink.displayName = 'AdapterLink';

const UXBOARD = 'UX-Board';
const EMPLOYEE = 'Mitarbeitende';
const PROJECTS = 'Projekte';
const CAPACITYUTILISATION = 'Auslastung';

export interface ToolBarTopProps {
  onProjectSearchClick: (dialogMode: DialogMode) => void;
}

const ToolbarTop = (props: ToolBarTopProps): JSX.Element => {
  const { onProjectSearchClick } = props;
  const msal = useMsal();
  const classes = useStyles();

  const location = useLocation();

  const getMenuLabel = (pathname: string) => {
    switch (pathname) {
      case '/mitarbeitende': {
        return EMPLOYEE;
      }
      case '/projekte': {
        return PROJECTS;
      }
      case '/projekte/archiv': {
        return PROJECTS;
      }
      case '/auslastung': {
        return CAPACITYUTILISATION;
      }
      default: {
        return EMPLOYEE;
      }
    }
  };

  const [state, setState] = useState({
    menuLabelSelected: getMenuLabel(location.pathname),
    menuLabelHovered: null,
  });

  const [teamFilter, setTeamFilter] = useState('alle');

  // when url changes, update teamfilter
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has('team')) {
      const teamValue = searchParams.get('team');
      setTeamFilter(teamValue);
    } else {
      setTeamFilter('alle');
    }
  }, [location.search]);

  // Fixing Initial Navigation CSS Bug for fast connections by deferring setting the state
  useEffect(() => {
    const handleLoad = async () => {
      await new Promise((resolve) => setTimeout(resolve, 50)); // wait until ready
      setState((prev) => ({ ...prev, menuLabelSelected: getMenuLabel(location.pathname) }));
    };

    handleLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setState((prev) => ({ ...prev, menuLabelSelected: getMenuLabel(location.pathname) }));
  }, [location.pathname]);

  const handleMouseEnter = (tab: string) => {
    setState((prev) => ({ ...prev, menuLabelHovered: tab }));
  };

  const handleMouseLeave = () => {
    setState((prev) => ({ ...prev, menuLabelHovered: null }));
  };

  const moveCursorFollowDiv = (menuLabelHovered: string | null, menuLabelSelected: string): CSSProperties => {
    const tag = !menuLabelHovered ? menuLabelSelected : menuLabelHovered;
    const menuLabels = document.getElementsByClassName(classes.menuLabel);
    const mainNavigation = document.getElementsByTagName('nav')[0];
    const mainNaviX = mainNavigation ? mainNavigation.getBoundingClientRect().x : 0;

    let width, leftPosition;

    Array.from(menuLabels).forEach((element) => {
      if (tag === element.innerHTML) {
        width = element.getBoundingClientRect().width;
        leftPosition = element.getBoundingClientRect().x - mainNaviX;
        (element as HTMLElement).style.color = colors.white;
      } else {
        (element as HTMLElement).style.color = colors.mainColor;
      }
    });

    return { width: width, left: `${leftPosition}px` };
  };

  const handleLogout = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    const { instance, accounts } = msal;
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: '/',
    };
    instance.logoutRedirect(logoutRequest);
  };

  return (
    <div>
      <AppBar color="default" className={classes.root}>
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <div className={classes.secondaryNavigation}>
            <ListItem
              component={AdapterLink}
              to={teamFilter !== 'alle' ? `/?team=${teamFilter}` : '/'}
              className={classes.listItem}
            >
              <Typography className={classes.logo}>{UXBOARD}</Typography>
            </ListItem>
          </div>
          <List component="nav" aria-label="main navigation" className={classes.mainNavigation}>
            <ListItem
              component={AdapterLink}
              to={teamFilter !== 'alle' ? `/mitarbeitende?team=${teamFilter}` : '/mitarbeitende'}
              className={classes.listItem}
              onMouseEnter={() => handleMouseEnter(EMPLOYEE)}
              onMouseLeave={() => handleMouseLeave()}
            >
              <Typography className={`${classes.menuLabel}`}>{EMPLOYEE}</Typography>
            </ListItem>
            <ListItem
              component={AdapterLink}
              to={teamFilter !== 'alle' ? `/projekte?team=${teamFilter}` : '/projekte'}
              className={classes.listItem}
              onMouseEnter={() => handleMouseEnter(PROJECTS)}
              onMouseLeave={() => handleMouseLeave()}
            >
              <Typography className={classes.menuLabel}>{PROJECTS}</Typography>
            </ListItem>
            <ListItem
              component={AdapterLink}
              to={teamFilter !== 'alle' ? `/auslastung?team=${teamFilter}` : '/auslastung'}
              className={classes.listItem}
              onMouseEnter={() => handleMouseEnter(CAPACITYUTILISATION)}
              onMouseLeave={() => handleMouseLeave()}
            >
              <Typography className={classes.menuLabel}>{CAPACITYUTILISATION}</Typography>
            </ListItem>
            <div
              className={classes.cursorFollower}
              style={moveCursorFollowDiv(state.menuLabelHovered, state.menuLabelSelected)}
            />
          </List>

          <div className={classes.toolbarRight}>
            <ProjectSearchEditField onProjectSearchClick={onProjectSearchClick} />
            <Divider orientation="vertical" />

            <div className={classes.logOut}>
              <IconButton
                className={classes.logOutButton}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                size="small"
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faArrowRightToBracket} className={classes.logoutButtonIcon} />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default ToolbarTop;
