/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCalendar } from '@fortawesome/sharp-light-svg-icons';
import { FormControl, FormLabel, Theme, Typography, makeStyles } from '@material-ui/core';
import { DatePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import React from 'react';

import { BandeinsStrangeBold, senRegular } from '../../fonts/fonts';
import colors from '../../utils/colors';
import { DATEFORMAT } from '../../utils/dateUtils';

interface StyleProps {
  width: number | string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  formControl: ({ width }) => ({
    width: width ? width : '172px',
  }),
  calendarIcon: { color: colors.grey600 },
  arrows: {
    '&:hover': {
      cursor: 'pointer',
    },
    padding: '8px',
    height: '16px',
    width: '16px',
  },
  day: {
    '&.MuiPickersDay-dayOutsideMonth': {
      color: `${colors.grey400} !important`,
    },
    '&.MuiPickersDay-root': {
      width: '30px !important',
      height: '30px !important',
    },
    '&.MuiPickersDay-root[aria-selected="true"]': {
      backgroundColor: `${colors.grey50} !important`,
      border: `1px solid ${colors.mainColor}`,
    },
    '&.MuiPickersDay-dayWithMargin': {
      margin: '0px !important',
    },
    fontSize: '14px !important',
    fontFamily: `${senRegular} !important`,
    color: `${colors.mainColor} !important`,
  },
  calenderHeader: {
    margin: '0px 10px 5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  popper: {
    '& .MuiPickersPopper-paper': {
      boxShadow: 'none !important',
    },
    '& .MuiPickersLayout-contentWrapper, .MuiPickersPopper-paper': {
      width: '292px !important',
      height: '310px !important',
      borderRadius: '24px !important',
      boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.15)',
    },

    '& .MuiDayCalendar-weekDayLabel': {
      fontSize: '14px !important',
      fontFamily: `${senRegular} !important`,
      color: `${colors.mainColor} !important`,
      width: '30px !important',
      height: 'auto !important',
    },

    '& .MuiDayCalendar-header, .MuiDayCalendar-weekContainer': {
      justifyContent: 'space-evenly !important',
      margin: '4px 4px 5px !important',
    },

    '& .MuiDateCalendar-root': {
      width: 'auto !important',
      margin: '20px 0px !important',
      padding: '0px 4px 8px !important',
    },
    '& .MuiDayCalendar-header': {
      justifyContent: 'space-evenly !important',
      margin: '0px 4px 5px !important',
    },

    '& .MuiPickersDay-root[disabled]': {
      color: `${colors.grey400} !important`,
    },
  },
}));

interface MUIEXXDatePickerProps {
  formLabel?: string;
  fieldName: string;
  value: Dayjs | null;
  onChange: (value: Dayjs, fieldName: string) => void;
  disabled?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  handleDateError?: (error: DateValidationError, value: Dayjs) => void;
  dateErrorMessage?: string;
  textfieldWidth?: number | string;
  formControlStyle?: string;
}

const MUIEXXDatePicker = (props: MUIEXXDatePickerProps): JSX.Element => {
  const {
    onChange,
    value,
    formControlStyle,
    fieldName,
    minDate,
    maxDate,
    handleDateError,
    dateErrorMessage,
    formLabel: label,
    disabled,
    textfieldWidth,
  } = props;
  const classes = useStyles({ width: textfieldWidth });

  return (
    <FormControl className={`${classes.formControl} ${formControlStyle}`}>
      {label && <FormLabel>{label}</FormLabel>}
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={deDE.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <DatePicker
          disabled={disabled}
          disableHighlightToday={true}
          name={fieldName}
          format={DATEFORMAT}
          minDate={minDate}
          maxDate={maxDate}
          value={value ? value : null}
          referenceDate={dayjs()}
          defaultValue={dayjs()}
          showDaysOutsideCurrentMonth={true}
          dayOfWeekFormatter={(_day: string, date: Dayjs) => date.locale('de').format('dd').toUpperCase()}
          onChange={(value) => {
            onChange(value, fieldName);
          }}
          onError={handleDateError}
          slotProps={{
            textField: {
              helperText: dateErrorMessage,
              variant: 'standard',
              placeholder: 'TT.MM.JJJJ',
            },
            popper: {
              className: classes.popper,
            },
            day: {
              className: classes.day,
            },
          }}
          slots={{
            openPickerIcon: () => (
              <FontAwesomeIcon icon={faCalendar} className={classes.calendarIcon} width={16} height={16} />
            ),
            calendarHeader: (props) => {
              return (
                <div className={classes.calenderHeader}>
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    onClick={() => {
                      const month = props.currentMonth;
                      const previousMonth = month.subtract(1, 'month');
                      props.onMonthChange(previousMonth, 'right');
                    }}
                    className={classes.arrows}
                  />
                  <Typography variant={'h5'} style={{ fontFamily: BandeinsStrangeBold }}>
                    {props.currentMonth.locale('de').format('MMMM YYYY')}
                  </Typography>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    onClick={() => {
                      const month = props.currentMonth;
                      const nextMonth = month.add(1, 'month');
                      props.onMonthChange(nextMonth, 'left');
                    }}
                    className={classes.arrows}
                  />
                </div>
              );
            },
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default MUIEXXDatePicker;
