import { Project } from '../redux/actions/ProjectActions';
import { Employee } from '../redux/reducers/employee/employee.type';

const INTERNAL_EMPLOYEE_EMAIL_SUFFIX = '@exxeta.com';

export const employeeExist = (employeeList: Employee[], employee: Employee): boolean => {
  if (employeeList) {
    for (const empl of employeeList) {
      if (empl.email === employee.email) {
        return true;
      }
    }
  }
  return false;
};

export const getEmployeeIndex = (employeeList: Employee[], employee: Employee): number => {
  if (employeeList) {
    for (let index = 0; index < employeeList.length; index++) {
      if (employeeList[index].email === employee.email) {
        return index;
      }
    }
  }
  return -1;
};

export const STANDARD_FALLBACK = '/Standard.jpg';

export const getEmployeeFromId = (id: string, employeeList: Employee[]): Employee => {
  for (const employee of employeeList) {
    if (id === employee.id) {
      return employee;
    }
  }
  return null;
};

export const createEmailFromUniqueEmployeeName = (uniqueEmployeeName: string): string => {
  const emailSuffix = INTERNAL_EMPLOYEE_EMAIL_SUFFIX;
  const emailPrefix = replaceUnderscoresWithPeriods(uniqueEmployeeName);
  return emailPrefix + emailSuffix;
};

// Assumes: The URL has been defined by unique employee name, which in turn has been created from a given internal email.
export const createUniqueEmployeeNameFromUrl = (url: string): string => {
  const pathArray = url.split('/');
  const indexBefore = pathArray.findIndex((value) => value === 'profil');
  return pathArray[indexBefore + 1];
};

export const replaceUnderscoresWithPeriods = (string: string): string => {
  // let reUnderscoresGlobal = new RegExp('_','g'); TODO: Regular Expression Constructor will cause code to be skipped. Use literal instead.
  return string.replace(/_/g, '.');
};

export const replacePeriodsWithUnderscores = (string: string): string => {
  // let rePeriodsGlobal = new RegExp('.','g') TODO: Regular Expression Constructor will cause code to be skipped. Use literal instead.
  return string.replace(/\./g, '_');
};

export const createUniqueEmployeeNameFromEmail = (email: string): string => {
  if (email && email.includes(INTERNAL_EMPLOYEE_EMAIL_SUFFIX)) {
    const uniqueEmailPrefix = email.split('@')[0];
    return replacePeriodsWithUnderscores(uniqueEmailPrefix);
  }
  console.warn(`Could not create employee image URL for email '${email}':
      Did not match required pattern '${INTERNAL_EMPLOYEE_EMAIL_SUFFIX}'`);
  return '';
};

export const sortProjects = (projects: Project[]): Project[] => {
  if (!projects) {
    return [];
  }
  return projects.sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });
};

export const getEmployeeFromEmail = (email: string, employeeList: Employee[]): Employee => {
  for (const employee of employeeList) {
    if (email === employee.email) {
      return employee;
    }
  }
  //console.warn(`Could not find corresponding email '${email}' in employee list`, email, employeeList);
  return null;
};

export const formatNames = (names: string[]): string => {
  const length = names.length;

  if (length === 0) {
    return '';
  } else if (length === 1) {
    return names[0];
  } else if (length === 2) {
    return names.join(' und ');
  } else {
    // Für mehr als zwei Namen
    const lastName = names.pop(); // Entferne den letzten Namen
    return names.join(', ') + ' und ' + lastName;
  }
};

export const sortEmployeeList = (employees: Employee[]) => {
  return employees.sort((a, b) =>
    `${a.forename} ${a.surname}`.toUpperCase().localeCompare(`${b.forename} ${b.surname}`.toUpperCase()),
  );
};
