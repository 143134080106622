import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { setWorkloadLimit } from '../../redux/actions/WorkloadActions';
import { ApplicationStore } from '../../redux/reducers';
import FilterBase, { FilterValue } from './FilterBase';

export enum EmployeeWorkloadFilterValues {
  '<25%' = 25,
  '<50%' = 50,
  '<75%' = 75,
  '>75%' = 76,
  '>100%' = 101,
  'all' = Number.MAX_VALUE,
}

interface EmployeeWorkloadFilterProps {
  workloadLimit: EmployeeWorkloadFilterValues;
}

const EmployeeWorkloadFilter = (props: EmployeeWorkloadFilterProps): JSX.Element => {
  const { workloadLimit } = props;
  const dispatch = useDispatch();
  const workloadLimitFilterValuesItems = [
    { value: EmployeeWorkloadFilterValues['all'], label: 'alle' },
    { value: EmployeeWorkloadFilterValues['<25%'], label: 'unter 25 %' },
    { value: EmployeeWorkloadFilterValues['<50%'], label: 'unter 50 %' },
    { value: EmployeeWorkloadFilterValues['<75%'], label: 'unter 75 %' },
    { value: EmployeeWorkloadFilterValues['>75%'], label: 'über 75 %' },
    { value: EmployeeWorkloadFilterValues['>100%'], label: 'über 100 %' },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const employeeWorkloadFilterItemClicked = (item: FilterValue) => {
    dispatch(setWorkloadLimit(item.value));
    handleMenuClose();
  };

  const handleEmployeeWorkloadFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <FilterBase
      filterHeading={'Auslastung'}
      open={open}
      anchorEl={anchorEl}
      filterState={workloadLimitFilterValuesItems.find((item) => item.value === workloadLimit).label}
      filterValuesItems={workloadLimitFilterValuesItems}
      handleMenuItemClick={employeeWorkloadFilterItemClicked}
      handleFilterClick={handleEmployeeWorkloadFilterClick}
      handleMenuClose={handleMenuClose}
    />
  );
};

const mapStateToProps = (
  store: ApplicationStore,
): {
  workloadLimit: EmployeeWorkloadFilterValues;
} => {
  return {
    workloadLimit: store.workload.workloadLimit,
  };
};
export default connect(mapStateToProps)(EmployeeWorkloadFilter);
