import React, { useEffect } from 'react';

export const useDebounce = (value: string, delay: number): string => {
  //No value by inilizing would throw exceptions
  if (!value) {
    value = 'initialValue';
  }
  const [newValue, setNewValue] = React.useState(value);
  useEffect(() => {
    const id = setTimeout(() => {
      setNewValue(value);
    }, delay);
    return () => {
      clearTimeout(id);
    };
  });
  return newValue;
};
