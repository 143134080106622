const colors = {
  // main Farben
  mainColor: '#000000',
  white: '#ffffff',

  // Neutral
  grey20: '#fafafa',
  grey50: '#F2F2F2',
  grey100: '#e6e6e6',
  grey200: '#cccccc',
  grey400: '#999999',
  grey600: '#666666',
  grey800: '#333333',

  // Akzent-Farben
  turqoise500: '#46DCFF',
  turqoise200: '#B5F1FF',
  purple500: '#8CA5FF',
  purple200: '#D1DBFF',
  yellow500: '#EBFF59',
  yellow200: '#F7FFBD',

  // semantische Farben
  red500: '#EB0032',
  red100: '#FBCCD6',
  orange500: '#E69600',
  green500: '#008700',

  errorInfo: '#FFD4DC',
  successInfo: '#CCE7CC',
  warningInfo: '#FAEACC',

  //Für Darstellung der Projekte in Auslastung
  noBorder: 'none',
  withBorder: '2px solid #000000',

  // Farbe Auslaustungslinie
  greyLines: '#bbb',
  transparent: 'transparent',

  // Hintergrundfarbe bei geringer Auslastung
  redTimelineColor: '#EB003240',
};
export default colors;
