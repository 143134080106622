import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { setWorkloadProject } from '../../redux/actions/WorkloadActions';
import { ApplicationStore } from '../../redux/reducers';
import FilterBase, { FilterValue } from './FilterBase';

export enum WorkloadProjectFilterValues {
  'all',
  'billable',
  'notBillable',
}

interface WorkloadProjectFilterProps {
  workloadProjects: WorkloadProjectFilterValues;
}

const WorkloadProjectFilter = (props: WorkloadProjectFilterProps): JSX.Element => {
  const { workloadProjects } = props;
  const dispatch = useDispatch();
  const workloadFilterValuesItems = [
    { value: WorkloadProjectFilterValues.all, label: 'alle Projekte' },
    { value: WorkloadProjectFilterValues.billable, label: 'fakturierbare Projekte' },
    { value: WorkloadProjectFilterValues.notBillable, label: 'nicht fakturierbare Projekte' },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const workloadProjectFilterItemClicked = (item: FilterValue) => {
    dispatch(setWorkloadProject(item.value));
    handleMenuClose();
  };

  const handleWorkloadProjectFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <FilterBase
      filterHeading={'Auslastung bezogen auf'}
      open={open}
      anchorEl={anchorEl}
      filterState={workloadFilterValuesItems.find((item) => item.value === workloadProjects).label}
      filterValuesItems={workloadFilterValuesItems}
      handleMenuItemClick={workloadProjectFilterItemClicked}
      handleFilterClick={handleWorkloadProjectFilterClick}
      handleMenuClose={handleMenuClose}
    />
  );
};

const mapStateToProps = (
  store: ApplicationStore,
): {
  workloadProjects: WorkloadProjectFilterValues;
} => {
  return {
    workloadProjects: store.workload.workloadProjectType,
  };
};
export default connect(mapStateToProps)(WorkloadProjectFilter);
