import { routerMiddleware } from 'connected-react-router';
import { History, createHashHistory } from 'history';
import { Action, Store, applyMiddleware, compose, createStore } from 'redux';
import { Persistor, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import { ApplicationStore, reducers } from './redux/reducers';

const composeEnhancer = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const history: History = createHashHistory();
export default function configureStore(preloadedState?: ApplicationStore): {
  store: Store<ApplicationStore>;
  persistor: Persistor;
} {
  const store = createStore<ApplicationStore, Action<any>, unknown, unknown>(
    reducers(history),
    preloadedState,
    composeEnhancer(
      applyMiddleware(
        thunk,
        //logger,
        routerMiddleware(history), // for dispatching history actions
      ),
    ),
  );
  const persistor = persistStore(store);
  return { persistor, store };
}
