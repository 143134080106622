import dayjs from 'dayjs';

export const DATEFORMAT = 'DD.MM.YYYY';
const getDaysInMonth = (date: dayjs.Dayjs): number => {
  return date.daysInMonth();
};

const isValidDate = (dateString: string): boolean => {
  return dateString && dayjs(dateString, DATEFORMAT).isValid();
};

const getNextMonday = () => {
  let nextMonday = dayjs();
  if (nextMonday.day() === 1) {
    if (nextMonday.hour() > 11) {
      nextMonday = nextMonday.add(1, 'day');
    }
  }
  // Solange der Wochentag nicht Montag ist (1), füge einen Tag hinzu
  while (nextMonday.day() !== 1) {
    nextMonday = nextMonday.add(1, 'day');
  }

  return nextMonday;
};

export const timePeriodsOverlapping = (start1: string, end1: string, start2: string, end2: string): boolean => {
  return (
    dayjs(start1, DATEFORMAT).isBefore(dayjs(end2, DATEFORMAT)) &&
    dayjs(start2, DATEFORMAT).isBefore(dayjs(end1, DATEFORMAT))
  );
};

export { getDaysInMonth, isValidDate, getNextMonday };
