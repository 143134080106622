import { MenuItem, makeStyles } from '@material-ui/core';
import React from 'react';

interface CustomMenuItemProps {
  label: string;
  selected: boolean;
  onMenuItemClick: (e: React.MouseEvent) => void;
}

const useStyles = makeStyles(() => ({
  iconCell: {
    width: '32px',
  },
}));

const CustomMenuItem = (props: CustomMenuItemProps): JSX.Element => {
  const { label, selected, onMenuItemClick } = props;
  const classes = useStyles();
  return (
    <MenuItem key={label} selected={selected} disableRipple onClick={onMenuItemClick}>
      {label}
      <div className={classes.iconCell} />
    </MenuItem>
  );
};

export default CustomMenuItem;
