import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { getIdToken } from './jwtSessionUtils';

const URL_PREFIX = process.env.REACT_APP_API_ENDPOINT;

const axiosInstance = axios.create({
  baseURL: URL_PREFIX,
  headers: {
    Accept: '*',
  },
});

axiosInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await getIdToken();
    if (token) config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export const getData = <R>(url: string): Promise<AxiosResponse<R>> => {
  return axiosInstance
    .get(url)
    .then((response: AxiosResponse<R>) => {
      return response;
    })
    .catch((err: AxiosError) => {
      throw err.response;
    });
};

export const getBlobData = (url: string): Promise<Blob> => {
  return axiosInstance
    .get(url, { responseType: 'blob' }) // responseType auf 'blob' setzen
    .then((response: AxiosResponse<Blob>) => {
      return response.data; // Gib die Blob-Daten zurück
    })
    .catch((err: AxiosError) => {
      throw err.response;
    });
};

export const getAccess = <R>(url: string, requestOptions: AxiosRequestConfig): Promise<AxiosResponse<R>> => {
  return axiosInstance
    .get(url, requestOptions)
    .then((response: AxiosResponse<R>) => {
      return response;
    })
    .catch((err: AxiosError) => {
      throw err;
    });
};

export const putData = <T, R>(url: string, data: T): Promise<AxiosResponse<R>> => {
  return axiosInstance
    .put(url, data)
    .then((response: AxiosResponse<R>) => {
      return response;
    })
    .catch((err: AxiosError) => {
      throw err.response;
    });
};

export const postData = <T, R>(url: string, data: T): Promise<AxiosResponse<R>> => {
  return axiosInstance
    .post(url, data)
    .then((response: AxiosResponse<R>) => {
      return response;
    })
    .catch((err: AxiosError) => {
      throw err.response;
    });
};

export const deleteApi = <R>(url: string, requestOptions?: AxiosRequestConfig): Promise<AxiosResponse<R>> => {
  return axiosInstance
    .delete(url, requestOptions)
    .then((res: AxiosResponse<R>) => {
      return res;
    })
    .catch((err: AxiosError) => {
      throw err.response;
    });
};
