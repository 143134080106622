import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import LoginAgain from '../pages/LoginAgain';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const { instance } = useMsal();
  const activeAccount: AccountInfo = instance.getActiveAccount();

  return (
    <Route
      {...rest}
      render={(props) => {
        return activeAccount ? (
          <Component {...props} />
        ) : (
          <LoginAgain
            heading="Das hat leider nicht geklappt..."
            subHeading="Bei der Anmeldung ist ein Fehler aufgetreten"
            logInButtonText="Erneut versuchen"
          />
        );
      }}
    />
  );
};
export default PrivateRoute;
