import { WeeklyStandupFilterValues } from '../../../components/Filter/WeeklyStandupFilter';
import { WorkloadStatusFilterValues } from '../../../components/Filter/WorkloadStatusFilter';
import { Absence } from '../../../components/status/Abwesenheitsstatus';

export interface EmployeeStore {
  teamData: TeamData[];
  employeeData: Employee[];
  displayedEmployees: Employee[];
  openEmployeeCards: number[];
  shownSpecificTeam: string;
  statusTag: string[];
  profileImage: string;
  weeklyStandupFilter: WeeklyStandupFilterValues;
  workloadStatusFilter: WorkloadStatusFilterValues;
  currentEmployee: Employee;
}

export interface Employee {
  email?: string;
  forename?: string;
  id?: string;
  projects?: string[];
  surname?: string;
  teams?: string[];
  status?: Status;
  position: string;
}
export interface TeamData {
  teamName: string;
  subTeams: TeamData[];
}

export interface WorkloadStatus {
  iHaveTime: boolean;
  iNeedHelp: boolean;
}

export enum Workload {
  I_HAVE_TIME = 'iHaveTime',
  I_NEED_HELP = 'iNeedHelp',
}

export enum WeeklyStandup {
  I_AM_HERE = 'I_AM_HERE',
  I_AM_ABSENT = 'I_AM_ABSENT',
  I_AM_PERMANENTLY_ABSENT = 'I_AM_PERMANENTLY_ABSENT',
}

export type WeeklyStandupStatus =
  | WeeklyStandup.I_AM_HERE
  | WeeklyStandup.I_AM_ABSENT
  | WeeklyStandup.I_AM_PERMANENTLY_ABSENT;

export interface Status {
  workloadStatus: WorkloadStatus;
  weeklyStandupStatus: WeeklyStandupStatus;
  absenceStatus: Absence[];
}
