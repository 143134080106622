import { Tooltip, TooltipProps, makeStyles } from '@material-ui/core';
import { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';

import colors from '../../utils/colors';

// Entfernen Sie das children-Prop aus der Erweiterung, da es bereits in TooltipProps definiert ist
interface MUIEXXTooltipBaseProps extends TooltipProps {
  showTooltip?: boolean;
}

const useStyles = makeStyles((theme) => ({
  tooltip: {
    [`& .${tooltipClasses.arrow}`]: {
      color: colors.turqoise500,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colors.turqoise500,
      padding: '8px',
    },
  },
}));

const MUIEXXTooltipBase: React.FC<MUIEXXTooltipBaseProps> = (props) => {
  const { children, showTooltip, ...otherProps } = props;
  const classes = useStyles();

  return showTooltip ? (
    <Tooltip {...otherProps} arrow classes={{ popper: classes.tooltip }}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export default MUIEXXTooltipBase;
