import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { Component, ErrorInfo } from 'react';

interface CatchProps {
  children: React.ReactNode;
}

export default class Catch extends Component<CatchProps> {
  state = { hasError: false };

  static getDerivedStateFromError = (error: Error): { hasError: boolean } => {
    console.log(error);
    // Update the state, at the next rendering, we'll know that an error has been caught
    return { hasError: true };
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log(error, errorInfo);
  }

  retry = (): void => {
    this.setState({
      hasError: false,
    });
  };

  handleRetry = (): void => {
    this.retry();
  };

  render(): React.ReactNode {
    const { children } = this.props;
    if (this.state.hasError) {
      // Display of the fallback of our choice
      return (
        <div>
          <Typography variant="h1">Hier ist etwas schief gelaufen.</Typography>
          <Button variant="outlined" color="primary" onClick={this.handleRetry}>
            Erneut versuchen
          </Button>
        </div>
      );
    }
    return children;
  }
}
