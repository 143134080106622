import { useMsal } from '@azure/msal-react';
import { Button, Typography } from '@material-ui/core';
import { StyleRules, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { loginRequest } from '../auth-config';
import exxeta_work_negativ from '../images/exxeta_work_negativ 2.svg';
import colors from '../utils/colors';

const useStyles = makeStyles(
  (theme: any): StyleRules<string> => ({
    root: {
      display: 'flex',
      margin: 0,
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },

    leftInfo: {
      padding: '0 120px',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '48px',
      backgroundColor: colors.mainColor,
    },

    leftHeading: {
      marginTop: 0,
      marginBottom: 0,
      textAlign: 'center',
      color: colors.white,
      backgroundColor: colors.mainColor,
    },

    section: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },

    leftParagraph: {
      color: colors.white,
      backgroundColor: colors.mainColor,
      textAlign: 'center',
    },
    tryAgainSection: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '120px',
      paddingTop: '320px',
      justifyContent: 'center',
      gap: '48px',
    },

    tryAgainText: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    rightInfo: {
      width: '100%',
    },
  }),
);
export interface LoginAgainProps {
  heading: string;
  subHeading: string;
  logInButtonText: string;
}

const LoginAgain = (props: LoginAgainProps): JSX.Element => {
  const { heading, subHeading, logInButtonText } = props;
  const classes = useStyles();
  const { instance } = useMsal();

  const handleLoginRedirect = (): void => {
    instance.loginRedirect({ ...loginRequest, prompt: 'create' }).catch((error) => console.log(error));
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftInfo}>
        <img src={exxeta_work_negativ} alt="exxeta_work_negativ" />
        <div className={classes.section}>
          <Typography variant="h2" className={classes.leftHeading}>
            Dein UX-Board
          </Typography>
          <div>
            <Typography variant="body1" className={classes.leftParagraph}>
              Mitarbeitende, Projekte und Kapazitäten -
            </Typography>
            <Typography variant="body1" className={classes.leftParagraph}>
              alles auf einen Blick
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.rightInfo}>
        <div className={classes.tryAgainSection}>
          <div className={classes.tryAgainText}>
            <Typography variant="h3">{heading}</Typography>
            <Typography variant="body1">{subHeading}</Typography>
          </div>
          <div>
            <Button variant="contained" color="primary" onClick={handleLoginRedirect}>
              {logInButtonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginAgain;
