import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { setEmployeeFilters } from '../../redux/actions/EmployeeActions';
import { ApplicationStore } from '../../redux/reducers';
import FilterBase, { FilterValue } from './FilterBase';

export enum WorkloadStatusFilterValues {
  'all',
  'iHaveTime',
  'iNeedHelp',
}

interface WorkloadStatusFilterProps {
  workloadStatusFilter: WorkloadStatusFilterValues; // Der aktuelle Filterstatus (Redux)
  isAllEmployeesLoaded: boolean;
}

export const SEARCH_PARAM_WORKLOAD_STATUS_FILTER = 'wochenauslastung';

const WorkloadStatusFilter = (props: WorkloadStatusFilterProps) => {
  const { workloadStatusFilter, isAllEmployeesLoaded } = props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // Filterwerten für Menü
  const workloadStatusFilterValuesItems: FilterValue[] = [
    { value: WorkloadStatusFilterValues.all, label: 'alle' },
    { value: WorkloadStatusFilterValues.iHaveTime, label: 'hat Zeit übrig' },
    { value: WorkloadStatusFilterValues.iNeedHelp, label: 'braucht Unterstützung' },
  ];

  // URL Filter Variablen
  const location = useLocation();
  const history = useHistory();

  const changeWorkloadStatusURL = (workloadStatus: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(SEARCH_PARAM_WORKLOAD_STATUS_FILTER, workloadStatus.toString());

    if (workloadStatus === 'alle') {
      searchParams.delete(SEARCH_PARAM_WORKLOAD_STATUS_FILTER);
    }

    const newSearchString = searchParams.toString();

    if (location.search !== newSearchString) {
      history.replace({ search: newSearchString });
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleWorkloadStatusFilterItemClicked = (filterValue: FilterValue): void => {
    dispatch(setEmployeeFilters({ workloadStatusFilter: filterValue.value }));
    changeWorkloadStatusURL(filterValue.label.replaceAll(' ', '-').toLowerCase());
    handleMenuClose();
  };

  const handleWorkloadFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(SEARCH_PARAM_WORKLOAD_STATUS_FILTER) && isAllEmployeesLoaded) {
      const workloadStatus = searchParams.get(SEARCH_PARAM_WORKLOAD_STATUS_FILTER);
      switch (workloadStatus) {
        case 'hat-zeit-übrig': {
          dispatch(setEmployeeFilters({ workloadStatusFilter: WorkloadStatusFilterValues.iHaveTime }));
          break;
        }
        case 'braucht-unterstützung': {
          dispatch(setEmployeeFilters({ workloadStatusFilter: WorkloadStatusFilterValues.iNeedHelp }));
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllEmployeesLoaded]);

  return (
    <FilterBase
      filterHeading={'Wochenauslastung'}
      open={open}
      anchorEl={anchorEl}
      filterState={workloadStatusFilterValuesItems.find((item) => item.value === workloadStatusFilter)?.label || 'Alle'}
      filterValuesItems={workloadStatusFilterValuesItems}
      handleMenuItemClick={handleWorkloadStatusFilterItemClicked}
      handleFilterClick={handleWorkloadFilterClick}
      handleMenuClose={handleMenuClose}
    />
  );
};

// Redux-Map-Methoden
const mapStateToProps = (store: ApplicationStore) => ({
  employees: store.employee.employeeData, // Array von Mitarbeitern
  workloadStatusFilter: store.employee.workloadStatusFilter,
  isAllEmployeesLoaded: store.appConfig.isAllEmployeesLoaded,
});

export default connect(mapStateToProps)(WorkloadStatusFilter);
