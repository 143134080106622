import { BillableFilterValues } from '../../components/Filter/BillableFilter';
import { EmployeeWorkloadFilterValues } from '../../components/Filter/EmployeeWorkloadFilter';
import { WorkloadProjectFilterValues } from '../../components/Filter/WorkloadProjectFilter';
import * as actionTypes from './ActionTypes';

interface DisplayRedBoxes {
  payload: boolean;
  type: typeof actionTypes.DISPLAY_RED_BOXES;
}

interface SetBillableFilterState {
  payload: BillableFilterValues;
  type: typeof actionTypes.SET_BILLABLE_FILTER_STATE;
}

interface SetWorkloadLimit {
  payload: EmployeeWorkloadFilterValues;
  type: typeof actionTypes.SET_EMPLOYEE_WORKLOAD_LIMIT;
}

interface SetWorkloadProjects {
  payload: WorkloadProjectFilterValues;
  type: typeof actionTypes.SET_WORKLOAD_PROJECTS;
}

interface ChangeEmployeeSearchValue {
  payload: string;
  type: typeof actionTypes.CHANGE_EMPLOYEE_SEARCH_VALUE;
}

export const displayRedBoxes = (isShowed: boolean): DisplayRedBoxes => {
  return {
    payload: isShowed,
    type: actionTypes.DISPLAY_RED_BOXES,
  };
};

export const setBillableFilterState = (state: BillableFilterValues): SetBillableFilterState => {
  return {
    payload: state,
    type: actionTypes.SET_BILLABLE_FILTER_STATE,
  };
};

export const setWorkloadLimit = (state: EmployeeWorkloadFilterValues): SetWorkloadLimit => {
  return {
    payload: state,
    type: actionTypes.SET_EMPLOYEE_WORKLOAD_LIMIT,
  };
};

export const setWorkloadProject = (state: WorkloadProjectFilterValues): SetWorkloadProjects => {
  return {
    payload: state,
    type: actionTypes.SET_WORKLOAD_PROJECTS,
  };
};

export const changeEmployeeSearchValue = (state: string): ChangeEmployeeSearchValue => {
  return {
    payload: state,
    type: actionTypes.CHANGE_EMPLOYEE_SEARCH_VALUE,
  };
};

export type DispatchedWorkloadAction =
  | DisplayRedBoxes
  | SetBillableFilterState
  | SetWorkloadLimit
  | SetWorkloadProjects
  | ChangeEmployeeSearchValue;
