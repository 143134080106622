import { faCalendarClock, faCircleCheck, faClockRotateLeft } from '@fortawesome/sharp-light-svg-icons';
import dayjs from 'dayjs';

import { InputSelectOption } from '../models/shared/InputSelectOption';
import { ProjectShort } from '../models/shared/ProjectShort';
import { Project, ProjectMember, ProjectStatus } from '../redux/actions/ProjectActions';
import { Employee } from '../redux/reducers/employee/employee.type';
import { DATEFORMAT, timePeriodsOverlapping } from './dateUtils';
import { getEmployeeFromEmail } from './employeeUtils';

export const deleteProjectById = (name: string, projects: Project[]): Project[] => {
  const tmpProjects: Project[] = JSON.parse(JSON.stringify(projects));
  if (name && tmpProjects && tmpProjects.length > 0) {
    for (let index = 0; index < tmpProjects.length; index++) {
      const project = tmpProjects[index];
      if (project && project.name && project.name === name) {
        tmpProjects.splice(index, 1);
      }
    }
  }
  return tmpProjects;
};

export const sortProjects = (projects: Project[]): Project[] => {
  if (!projects) {
    return [];
  }
  return projects.sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });
};

export const getProjectByName = (projectName: string, projects: Project[]): Project => {
  if (projectName && projects && projects.length > 0) {
    for (const project of projects) {
      if (project.name === projectName) {
        return project;
      }
    }
  }
  return null;
};

export const mapProjectToShortProject = (project: Project): ProjectShort => {
  return {
    name: project.name,
    id: project.name,
    customer: project.customer,
  };
};

export const mapProjectsToShortProjects = (projects: Project[]): ProjectShort[] => {
  return projects.map((project) => mapProjectToShortProject(project));
};

export const createSuggestionListOfProjectNames = (
  allProjects: ProjectShort[],
  inputValue: string | ProjectShort,
): InputSelectOption[] => {
  const options = allProjects.map<InputSelectOption>((project: ProjectShort) =>
    createInputSelectionOption(project.id, `${project.name} (${project.customer})`, project.customer),
  );

  if (inputValue) {
    options.push(
      createInputSelectionOption(
        'project.id',
        `„${inputValue}“ ${inputValue && 'als neues Projekt anlegen'} `,
        'customerKeyForPopOver',
      ),
    );
  }

  return options;
};

export const createListOfProjectNamesWithCustomer = (
  allProjects: ProjectShort[],
  employeeProjectList: string[],
): InputSelectOption[] => {
  return allProjects
    .filter((item) => employeeProjectList.includes(item.name))
    .map<InputSelectOption>((project: ProjectShort) =>
      createInputSelectionOption(project.id, project.name, project.customer),
    );
};

const createInputSelectionOption = (value: string, label: string, customer: string): InputSelectOption => {
  return {
    value: value,
    label: label,
    customer: customer,
  };
};

export const determineProjectStatus = (project: Project) => {
  const startDate = dayjs(project.startDate, DATEFORMAT);
  const endDate = dayjs(project.endDate, DATEFORMAT);

  if (dayjs().isAfter(startDate) && dayjs().isBefore(endDate)) return ProjectStatus.ACTIVE;

  if (dayjs().isAfter(endDate)) return ProjectStatus.COMPLETED;

  if (dayjs().isBefore(startDate)) return ProjectStatus.PLANNED;
};

export const determineProjectStatusIcon = (projectStatus: ProjectStatus) => {
  let icon;
  switch (projectStatus) {
    case ProjectStatus.ACTIVE: {
      icon = faClockRotateLeft;
      break;
    }
    case ProjectStatus.COMPLETED: {
      icon = faCircleCheck;
      break;
    }
    case ProjectStatus.PLANNED: {
      icon = faCalendarClock;
      break;
    }
  }
  return icon;
};

export enum Ordering {
  ASC = 'asc',
  DESC = 'desc',
}

export type Order = Ordering.ASC | Ordering.DESC;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === Ordering.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// stableSort() brings sort stability to non-modern browsers (notably IE11)
export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const overLappingEmployeeNames = (projectMembers: ProjectMember[], employees: Employee[]): string[] => {
  let members: ProjectMember[] = [];

  const groupedByEmail = projectMembers.reduce<{ [key: string]: ProjectMember[] }>((acc, member) => {
    if (!acc[member.employeeEmail]) {
      acc[member.employeeEmail] = [];
    }
    acc[member.employeeEmail] = [...acc[member.employeeEmail], member];
    return acc;
  }, {});

  Object.values(groupedByEmail).forEach((memberList) => {
    memberList.forEach((member1, index) => {
      memberList.slice(index + 1).forEach((member2) => {
        if (timePeriodsOverlapping(member1.startDate, member1.endDate, member2.startDate, member2.endDate)) {
          members = [...members, member1];
        }
      });
    });
  });
  return members
    .map((member) => getEmployeeFromEmail(member.employeeEmail, employees).forename)
    .filter((name, index, self) => {
      // remove duplicates
      return self.indexOf(name) === index;
    });
};
