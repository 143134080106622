import { FormControl, FormHelperText, FormLabel, Input, Theme, makeStyles } from '@material-ui/core';
import React, { ChangeEventHandler } from 'react';

interface MUIEXXFieldParams {
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  text: string;
  label?: string;
  id?: string;
  placeholder?: string;
  type?: string;
  required?: boolean;
  endAdornment?: React.ReactNode;
  errorInfo?: {
    isError: boolean;
    errorMessage: string;
  };
  multilineRows?: number;
}

interface StyleProps {
  paddingRight?: number | string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  root: { width: '100%' },
  inputField: ({ paddingRight }) => ({
    paddingRight: paddingRight,
  }),
}));

const MUIEXXField = (props: MUIEXXFieldParams): JSX.Element => {
  const { onChange, text, label, errorInfo, id, placeholder, required, multilineRows, type, endAdornment, className } =
    props;
  const classes = useStyles({ paddingRight: endAdornment ? '16px' : multilineRows ? '0px' : '24px' });
  return (
    <FormControl className={`${classes.root} ${className}`}>
      {label && (
        <FormLabel htmlFor="adornment-projectName" required={required}>
          {label}
        </FormLabel>
      )}
      <Input
        id={id}
        value={text}
        onChange={onChange}
        error={errorInfo?.isError || false}
        placeholder={placeholder}
        required={required}
        disableUnderline={true}
        multiline={multilineRows ? true : false}
        rows={multilineRows}
        type={type}
        endAdornment={endAdornment}
        className={classes.inputField}
      />
      {errorInfo?.isError && <FormHelperText error={errorInfo.isError}>{errorInfo.errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default MUIEXXField;
