import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { displayRedBoxes, setBillableFilterState } from '../../redux/actions/WorkloadActions';
import { ApplicationStore } from '../../redux/reducers';
import FilterBase, { FilterValue } from './FilterBase';

export enum BillableFilterValues {
  'all',
  'billable',
  'notBillable',
}

interface BillableFilterProps {
  billableFilter: BillableFilterValues;
}

const BillableFilter = (props: BillableFilterProps): JSX.Element => {
  const { billableFilter } = props;
  const dispatch = useDispatch();
  const billableFilterValuesItems = [
    { value: BillableFilterValues.all, label: 'alle' },
    { value: BillableFilterValues.billable, label: 'fakturierbar' },
    { value: BillableFilterValues.notBillable, label: 'nicht fakturierbar' },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const billableFilterItemClicked = async (item: FilterValue) => {
    try {
      dispatch(displayRedBoxes(false));
      await new Promise((resolve) => setTimeout(resolve, 0)); // wait until ready

      // billableFilterState CHANGE UPDATE
      dispatch(setBillableFilterState(item.value));
      await new Promise((resolve) => setTimeout(resolve, 0)); // wait until ready

      dispatch(displayRedBoxes(true));
    } catch (error) {
      console.error('Keymapping-Handling not working: ' + error);
    } finally {
      handleMenuClose();
    }
  };

  const handleBillableFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <FilterBase
      filterHeading={'Projekt-Typ'}
      open={open}
      anchorEl={anchorEl}
      filterState={billableFilterValuesItems.find((item) => item.value === billableFilter).label}
      filterValuesItems={billableFilterValuesItems}
      handleMenuItemClick={billableFilterItemClicked}
      handleFilterClick={handleBillableFilterClick}
      handleMenuClose={handleMenuClose}
    />
  );
};

const mapStateToProps = (
  store: ApplicationStore,
): {
  billableFilter: BillableFilterValues;
} => {
  return {
    billableFilter: store.workload.billableFilter,
  };
};
export default connect(mapStateToProps)(BillableFilter);
