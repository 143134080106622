import { TeamData } from '../redux/reducers/employee/employee.type';

// Rekursive Funktion, bei der die subteams hierarchisch aufgebaut werden.
export const createTeamData = (primaryTeam: string, subTeams: string[]): TeamData => {
  const createSubTeam = (parent: string, level: number): TeamData[] => {
    const nextSubTeams = getSubTeams(subTeams, parent, level).map((teamName) => ({
      teamName: teamName,
      subTeams: createSubTeam(teamName, level + 1),
    }));

    return nextSubTeams;
  };

  return { teamName: primaryTeam, subTeams: createSubTeam('', 2) };
};

const getSubTeams = (teamNames: string[], parent: string, level: number): string[] => {
  return teamNames.filter((teamName) => teamName.startsWith(parent) && teamName.split('.').length === level);
};

export const sortTeamDataManually = (teamData: TeamData[]): TeamData[] => {
  const all = teamData.find((team) => team.teamName === 'alle');
  const max = teamData.find((team) => team.teamName === 'max');
  const rest = teamData
    .filter((team) => team.teamName !== 'max' && team.teamName !== 'alle')
    .sort((a, b) => a.teamName.localeCompare(b.teamName, 'en-EN'));

  return [all, max, ...rest].filter(Boolean);
};
