import { Theme, createStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StyleRules } from '@material-ui/core/styles';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { Suspense, lazy, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import MUIEXXSnackbar from './components/BaseComponentsExxetaStyle/MUIEXXSnackbar';
import DialogNewProject, { DialogMode } from './components/Dialogs/DialogNewProject/DialogNewProject';
//UI Elements
import ToolbarTop from './components/ToolbarTop/ToolbarTop';
//store
import { initApp } from './redux/actions/AppInitAction';
import { changeDialogMode, setShowDialog } from './redux/actions/ProjectActions';
import { ApplicationStore } from './redux/reducers';
import { Employee } from './redux/reducers/employee/employee.type';
import { Toast } from './redux/reducers/toastReducer';
import { useMountHook } from './utils/useMount';

//Pages
const Auslastung = lazy(() => import('./pages/Auslastung'));
const Mitarbeiter = lazy(() => import('./pages/Mitarbeiter'));
const NotFound404 = lazy(() => import('./pages/NotFound404'));
const EmployeePage = lazy(() => import('./pages/employee/EmployeePage'));
const ProjektePage = lazy(() => import('./pages/projects/ProjektePage'));

const styles = (theme: Theme): StyleRules<string> =>
  createStyles({
    root: {
      display: 'flex',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    sticky: {
      position: 'sticky',
      top: 0,
      zIndex: 150,
    },
  });

export interface AppProps extends WithStyles<typeof styles> {
  toasts: Toast[];
  showDialog: boolean;
  dialogMode: DialogMode;
  employee: Employee;
}

const App = (props: AppProps): JSX.Element => {
  const ref = useRef();
  const { toasts, showDialog, dialogMode } = props;
  const dispatch = useDispatch();
  useMountHook(() => {
    dispatch(initApp());
  }, ref);

  const classes = props.classes;

  const handleClickProjectSearch = (dialogMode: DialogMode): void => {
    dispatch(changeDialogMode(dialogMode));
    dispatch(setShowDialog(true));
  };

  const handleCloseOfDialogNewProject = (): void => {
    dispatch(setShowDialog(false));
  };

  return (
    <div>
      <div className={classes.root}>
        <div>
          <CssBaseline />
        </div>
        {/* <NavLink to="/" exact activeStyle={{color: 'green'}}>App</NavLink>
                <NavLink to="/meinBoard" exact activeStyle={{color: 'green'}} >Mein Board</NavLink> */}
        <div className={classes.wrapper}>
          <div className={classes.sticky}>
            <ToolbarTop onProjectSearchClick={handleClickProjectSearch} />
          </div>
          <Switch>
            <Route path="/" exact>
              <Suspense fallback={<React.Fragment />}>
                <Mitarbeiter />
              </Suspense>
            </Route>
            <Route path="/mitarbeitende" exact>
              <Suspense fallback={<React.Fragment />}>
                <Mitarbeiter />
              </Suspense>
            </Route>
            <Route path="/auslastung" exact>
              <Suspense fallback={<React.Fragment />}>
                <Auslastung />
              </Suspense>
            </Route>
            <Route path="/mitarbeitende/profil/:name" exact>
              <Suspense fallback={<React.Fragment />}>
                <EmployeePage />
              </Suspense>
            </Route>
            <Route path="/mitarbeitende/profil/:name/abwesenheit-verwalten" exact>
              <Suspense fallback={<React.Fragment />}>
                <EmployeePage />
              </Suspense>
            </Route>
            <Route path="/projekte" exact>
              <Suspense fallback={<React.Fragment />}>
                <ProjektePage />
              </Suspense>
            </Route>
            <Route path="/projekte/archiv" exact>
              <Suspense fallback={<React.Fragment />}>
                <ProjektePage />
              </Suspense>
            </Route>
            <Route path="*">
              <Suspense fallback={<React.Fragment />}>
                <NotFound404 />
              </Suspense>
            </Route>
          </Switch>
        </div>
      </div>

      {toasts.map((toast: Toast) => (
        <MUIEXXSnackbar key={toast.id} toast={toast} />
      ))}
      {showDialog && (
        <DialogNewProject
          handleClose={handleCloseOfDialogNewProject}
          showDialog={showDialog}
          dialogNewProjectMode={dialogMode}
        />
      )}
    </div>
  );
};

App.defaultProps = {};

const mapStateToProps = (store: ApplicationStore) => {
  return {
    toasts: store.toasts,
    showDialog: store.project.showDialog,
    dialogMode: store.project.dialogNewProjectMode,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(App));
