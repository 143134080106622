import { AlertColor } from '@mui/material';

import { Toast } from '../reducers/toastReducer';
import { ADD_TOAST, REMOVE_TOAST } from './ActionTypes';

export interface AddToastAction {
  type: typeof ADD_TOAST;
  payload: Toast;
}

export interface RemoveToastAction {
  type: typeof REMOVE_TOAST;
  id: string;
}

function generateUUID() {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export const addToast = (message: string | JSX.Element, severity: AlertColor): AddToastAction => {
  return {
    type: ADD_TOAST,
    payload: {
      id: generateUUID(),
      severity,
      message,
    },
  };
};

export const removeToast = (id: string): RemoveToastAction => {
  return {
    type: REMOVE_TOAST,
    id,
  };
};
