import { FormControl, FormControlLabel, FormLabel, RadioGroup, Theme, makeStyles } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import * as React from 'react';
import { ChangeEvent } from 'react';

import colors from '../../utils/colors';

const radioStyles = {
  backgroundColor: colors.grey50,
  '&.Mui-checked': {
    color: colors.mainColor,
    backgroundColor: colors.turqoise500,
  },
  '&.MuiRadio-root': {
    color: colors.mainColor,
    width: '20px',
    height: '20px',
    margin: '6px 12px',
    border: '1px solid black',
  },
  "& svg:first-of-type path[d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z']":
    {
      visibility: 'hidden',
    },
};

const useStyles = makeStyles<Theme, { width: string | number }>((theme) => ({
  formControl: {
    alignItems: 'baseline',
    justifyContent: 'flex-start',
    userSelect: 'none',
    width: (props) => props.width, // Hier die width aus den Props verwenden
    '& .MuiFormGroup-root': {
      gap: '8px',
    },
  },
}));

interface RadioType {
  label: string;
  value: string | number | boolean;
}

interface MUIEXXRadioGroupProps {
  radios: RadioType[];
  selectedValue: string | number | boolean;
  heading?: string;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  width?: string | number;
  isRow?: boolean;
}

const MUIEXXRadioGroup = (props: MUIEXXRadioGroupProps): JSX.Element => {
  const { radios, selectedValue, heading, handleChange, width = 0, isRow = true } = props;
  const { formControl } = useStyles({ width });

  const controlProps = (item: boolean | number | string) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item.toString(),
    inputProps: { 'aria-label': item.toString() },
  });

  return (
    <FormControl className={formControl}>
      {heading && <FormLabel>{heading}</FormLabel>}
      <RadioGroup row={isRow} aria-labelledby={heading} name={heading}>
        {radios.map((radio) => (
          <FormControlLabel
            key={radio.label}
            value={radio.value}
            control={
              <Radio
                disableRipple
                disableFocusRipple
                disableTouchRipple
                {...controlProps(radio.value)}
                sx={radioStyles}
              />
            }
            label={radio.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default MUIEXXRadioGroup;
