import { AlertColor } from '@mui/material';

import { ADD_TOAST, REMOVE_TOAST } from '../actions/ActionTypes';
import { AddToastAction, RemoveToastAction } from '../actions/ToastActions';

export interface Toast {
  id?: string;
  severity: AlertColor;
  message: string | JSX.Element;
}

export type ToastStore = Toast[];

type ToastAction = AddToastAction | RemoveToastAction;

const initialState: ToastStore = [];

const toastReducer = (state = initialState, action: ToastAction): ToastStore => {
  switch (action.type) {
    case ADD_TOAST:
      return [...state, action.payload];
    case REMOVE_TOAST:
      return state.filter((toast) => toast.id !== action.id);
    default:
      return state;
  }
};

export default toastReducer;
