import * as actionTypes from './ActionTypes';

export interface UserChange {
  type: typeof actionTypes.USER_CHANGED;
  payload: string;
}

export interface AppStarted {
  type: typeof actionTypes.APP_STARTED;
}

export const AppStartedAction = (): AppStarted => ({
  type: actionTypes.APP_STARTED,
});

export type DispatchedAppSettingAction = AppStarted;
