import { RouterState, connectRouter } from 'connected-react-router';
import { History } from 'history';
import { Reducer, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage';

import appReducer, { AppReducerStore } from './appReducer';
import { EmployeeStore } from './employee/employee.type';
import employeeReducer from './employee/employeeReducer';
import projectReducer, { ProjectStore } from './projectReducer';
import toastReducer, { ToastStore } from './toastReducer';
import workloadReducer, { WorkLoadStore } from './workloadReducer';

const AppSettingsPersistConfig = {
  key: 'uxboard-app-store-appSettings',
  stateReconciler: hardSet,
  storage: storage,
  whitelist: ['user', 'themeType', 'appStartCount'],
};

export interface ApplicationStore {
  router: RouterState;
  appConfig: AppReducerStore;
  employee: EmployeeStore;
  project: ProjectStore;
  workload: WorkLoadStore;
  toasts: ToastStore;
}

export const reducers = (history: History): Reducer<ApplicationStore> =>
  combineReducers<ApplicationStore>({
    appConfig: persistReducer<any, any>(AppSettingsPersistConfig, appReducer),
    employee: employeeReducer,
    project: projectReducer,
    router: connectRouter(history),
    workload: workloadReducer,
    toasts: toastReducer,
  });
