import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { DialogMode } from '../../components/Dialogs/DialogNewProject/DialogNewProject';
import * as appServicePost from '../../services/appServices/appRefreshService';
import { getEmployeeFromEmail } from '../../utils/employeeUtils';
import { ApplicationStore } from '../reducers';
import { Employee } from '../reducers/employee/employee.type';
import * as actionTypes from './ActionTypes';
import { employeeDataAvailable } from './EmployeeActions';

export interface ProjectMember {
  employeeEmail: string;
  percentageTime: number;
  percentageProbability: number;
  startDate: string;
  endDate: string;
}

export interface Project {
  name: string;
  customer: string;
  description: string;
  projectMembers: ProjectMember[];
  archived: boolean;
  billable: boolean;
  startDate: string;
  endDate: string;
}

export enum ProjectStatus {
  ACTIVE = 'laufend',
  COMPLETED = 'abgeschlossen',
  PLANNED = 'geplant',
  ALL = 'alle',
}

export interface UpdatedProject extends Project {
  newProjectName: string;
}

export interface ProjectDataAvailable {
  payload: Project[];
  type: typeof actionTypes.PROJECT_DATA_AVAILABLE;
}

export interface ShowDialog {
  payload: boolean;
  type: typeof actionTypes.SET_SHOW_DIALOG;
}

export interface ChangeDialogMode {
  payload: DialogMode;
  type: typeof actionTypes.CHANGE_DIALOG_MODE;
}

export interface ChangeProjectStatus {
  payload: ProjectStatus;
  type: typeof actionTypes.CHANGE_PROJECT_STATUS;
}

export interface ArchiveProject {
  payload: Project[];
  type: typeof actionTypes.ARCHIVE_PROJECT;
}

export interface DeleteProjectConfirmed {
  payload: Project[];
  type: typeof actionTypes.DELETE_PROJECT_CONFIRMED;
}

export const projectDataAvailable = (projectData: Project[]): ProjectDataAvailable => {
  return {
    payload: projectData,
    type: actionTypes.PROJECT_DATA_AVAILABLE,
  };
};

export const addNewProject = (changesNewProject: Project, callback: Function) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>, getState: () => ApplicationStore) => {
    await appServicePost
      .postNewProject([changesNewProject], callback)
      .then((res: any) => {
        const state = getState();
        const projects = state.project.projectData;
        const employees = state.employee.employeeData;
        const affectedEmployeeMails = changesNewProject.projectMembers.map((member) => member.employeeEmail);
        const newEmployeeList = employees.map((employee) => ({
          ...employee,
          projects: affectedEmployeeMails.some((email) => email === employee.email)
            ? [...employee.projects, changesNewProject.name]
            : employee.projects,
        }));
        dispatch(projectDataAvailable([...projects, changesNewProject]));
        dispatch(employeeDataAvailable(newEmployeeList));

        return res.data;
      })
      .catch((err: any) => {
        return err.data;
      });
  };
};

export const sortProjectMemberByForenames = (a: ProjectMember, b: ProjectMember, employees: Employee[]) => {
  const forenameA = getEmployeeFromEmail(a.employeeEmail, employees)?.forename?.toUpperCase();
  const forenameB = getEmployeeFromEmail(b.employeeEmail, employees)?.forename?.toUpperCase();
  if (forenameA < forenameB) {
    return -1;
  }
  if (forenameA > forenameB) {
    return 1;
  }
  return 0;
};

export const updateProject = (oldProjectName: string, newProject: Project, callback: Function) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>, getState: () => ApplicationStore) => {
    const updatedProject: UpdatedProject = {
      ...newProject,
      newProjectName: newProject.name,
      name: oldProjectName,
    };
    await appServicePost
      .putUpdatedProject(updatedProject, callback)
      .then((res: any) => {
        const state = getState();
        const projects = state.project.projectData;
        const employees = state.employee.employeeData;
        const newProjectList = [newProject, ...projects.filter((project) => project.name !== oldProjectName)];
        const affectedEmployeeMails = newProject.projectMembers.map((projectMember) => projectMember.employeeEmail);

        const newEmployeeList = employees.map((employee) => ({
          ...employee,
          projects: affectedEmployeeMails.includes(employee.email)
            ? employee.projects.some((project) => project === oldProjectName)
              ? [...employee.projects.filter((project) => project !== oldProjectName), newProject.name]
              : [...employee.projects, newProject.name]
            : employee.projects,
        }));
        dispatch(projectDataAvailable(newProjectList));
        dispatch(employeeDataAvailable(newEmployeeList));
        return res.data;
      })
      .catch((err: any) => {
        return err.data;
      });
  };
};

export const archiveProject = (name: string, value: boolean) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>, getState: () => ApplicationStore) => {
    await appServicePost
      .archiveProject(name.toString(), value)
      .then(() => {
        const state = getState();
        const projects = state.project.projectData;
        const newProjectList = projects.map((project) =>
          project.name === name ? { ...project, archived: value } : project,
        );
        dispatch(projectDataAvailable(newProjectList));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteProjectConfirmedAction = (name: string) => {
  return async (dispatch: ThunkDispatch<ApplicationStore, null, AnyAction>, getState: () => ApplicationStore) => {
    await appServicePost
      .deleteProject(name)
      .then((res: any) => {
        const state = getState();
        const projects = state.project.projectData;
        const employees = state.employee.employeeData;
        const newProjectList = projects.filter((project) => project.name !== name);
        const newEmployeeList = employees.map((employee) => ({
          ...employee,
          projects: employee.projects.filter((project) => project !== name),
        }));
        dispatch(projectDataAvailable(newProjectList));
        dispatch(employeeDataAvailable(newEmployeeList));
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setShowDialog = (showDialog: boolean): ShowDialog => {
  return {
    payload: showDialog,
    type: actionTypes.SET_SHOW_DIALOG,
  };
};

export const changeDialogMode = (dialogMode: DialogMode): ChangeDialogMode => {
  return { payload: dialogMode, type: actionTypes.CHANGE_DIALOG_MODE };
};

export const changeProjectStatus = (projectStatus: ProjectStatus): ChangeProjectStatus => {
  return { payload: projectStatus, type: actionTypes.CHANGE_PROJECT_STATUS };
};

export type DispatchedProjectAction = ProjectDataAvailable | ShowDialog | ChangeDialogMode | ChangeProjectStatus;
