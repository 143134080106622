import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faChevronUp } from '@fortawesome/sharp-light-svg-icons';
import { Divider, IconButton, Menu, MenuItem, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core';
import { StyleRules, Theme } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import { NestedMenuItem } from 'mui-nested-menu';
import React from 'react';

import { senBold } from '../../fonts/fonts';
import colors from '../../utils/colors';
import CustomMenuItem from './CustomMenuItem';

export interface FilterValue {
  value: number;
  label: string;
  open?: boolean;
  subMenu?: FilterValue[];
}

interface FilterBaseProps extends WithStyles<typeof filterStyles> {
  filterHeading: string;
  open: boolean;
  anchorEl: HTMLElement;
  handleFilterClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleMenuClose: () => void;
  handleMenuItemClick: (item: FilterValue) => void;
  filterValuesItems: FilterValue[];
  filterState: string;
  subMenu?: FilterValue[];
}

const filterStyles = (theme: Theme): StyleRules =>
  createStyles({
    filterGroup: {
      display: 'flex',
      marginRight: '32px',
    },
    filterHeading: {
      fontFamily: senBold,
      color: colors.mainColor,
      fontSize: '16px',
      lineHeight: '24px',
      margin: '0px 16px 0px 0px',
    },
    arrowIcon: {
      color: colors.mainColor,
      height: 18,
      width: 18,
    },
    nestedMenuRoot: {
      display: 'flex',
      flexDirection: 'row',
      paddingRight: '0px',
    },
    nestedMenuItem: {
      minWidth: '0px',
      padding: '0px !important',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& > .MuiBox-root': {
        display: 'none !important',
      },
    },
    nestedLabel: {
      width: '100%',
      paddingRight: '12px',
    },
    nestedIcon: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: colors.grey400,
      paddingRight: '8px',
    },
    rightIcon: {
      width: '16px',
      height: '16px',
    },
    paperprops: {
      maxHeight: '500px',
    },
  });

const FilterBase = (props: FilterBaseProps): JSX.Element => {
  const {
    classes,
    filterHeading,
    open,
    anchorEl,
    filterState,
    filterValuesItems,
    handleFilterClick,
    handleMenuItemClick,
    handleMenuClose,
  } = props;

  const createMenuItems = (filterValuesItems: FilterValue[]) =>
    filterValuesItems.map((filterValue) => {
      if (filterValue.subMenu) {
        return (
          <MenuItem
            key={filterValue.value}
            className={classes.nestedMenuRoot}
            selected={filterValue.label === filterState}
            disableRipple
            onClick={(event) => {
              event.stopPropagation();
              handleMenuItemClick(filterValue);
            }}
          >
            <div className={classes.nestedLabel}>{filterValue.label}</div>
            <NestedMenuItem
              disableRipple
              component="div"
              parentMenuOpen={open}
              className={classes.nestedMenuItem}
              rightIcon={
                <div className={classes.nestedIcon}>
                  <Divider orientation="vertical" variant="middle" />
                  <FontAwesomeIcon icon={faChevronRight} className={classes.rightIcon} />
                </div>
              }
            >
              {createMenuItems(filterValue.subMenu)}
            </NestedMenuItem>
          </MenuItem>
        );
      } else {
        return (
          <CustomMenuItem
            key={filterValue.value}
            selected={filterValue.label === filterState}
            label={filterValue.label}
            onMenuItemClick={(event) => {
              event.stopPropagation();
              handleMenuItemClick(filterValue);
            }}
          />
        );
      }
    });

  return (
    <div className={classes.filterGroup}>
      <IconButton aria-label="more" onClick={handleFilterClick} disableRipple>
        <p className={classes.filterHeading}>
          {filterHeading}: {filterState}
        </p>
        <FontAwesomeIcon className={classes.arrowIcon} icon={open ? faChevronUp : faChevronDown} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={open}
        onClose={handleMenuClose}
        transitionDuration={0}
        PaperProps={{
          className: classes.paperprops,
        }}
      >
        <div>{createMenuItems(filterValuesItems)}</div>
      </Menu>
    </div>
  );
};

export default withStyles(filterStyles)(FilterBase);
